import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux"
import { Container, Row, Col } from "reactstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import wordmark from "../../static/logos/PIJ-WordMark.svg"
import eggplant from "../images/homepage/eggplant.png"
import hoodie from "../images/homepage/hoodie.png"
import writer from "../images/homepage/writer.png"
import raw from "../images/homepage/raw.png"

import {
  setMousePosition,
  setLoading,
  setHideBodyCopy,
  setBackgroundHoverImage,
  setCursorHoverImage,
} from "../state/home"

import { setOrderModalOpen } from "../state/cart"

const IndexPage = ({
  loading,
  hideBodyCopy,
  backgroundHoverImage,
  cursorHoverImage,
  hoverImageCount,
  mousePosition,
  orderDetails,
  dispatch,
}) => {
  useEffect(() => {
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 500)
  })

  function _onMouseMove(e) {
    dispatch(setMousePosition({ x: e.screenX, y: e.screenY }))
  }

  function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const hover_image_query = useStaticQuery(graphql`
    query {
      allRadioHourJson {
        edges {
          node {
            coverImage
            date
            title
          }
        }
      }
    }
  `)
  let shows = []
  let index = Object.keys(hover_image_query)[0]
  let edges = hover_image_query[index].edges
  edges.forEach(item => {
    shows.push(item.node)
  })
  shows.map((i, idx) => {
    return (i.date = new Date(i.date))
  })
  shows.sort(function(a, b) {
    return a.date - b.date
  })
  let rh_latest_cover = shows[shows.length - 1].coverImage

  let cursor_hover_image = ""

  if (cursorHoverImage) {
    let image
    if (cursorHoverImage === "radio-hour") {
      image = rh_latest_cover
    } else if (cursorHoverImage === "shop") {
      image = hoodie
    } else if (cursorHoverImage === "reviews") {
      image = writer
    } else if (cursorHoverImage === "six-feet") {
      image = eggplant
    } else if (cursorHoverImage === "instagram") {
      image = raw
    }
    let ch_style = {
      position: "absolute",
      width: 250 + "px",
      transform: "translate(-80%,-100%)",
      top: mousePosition.y + "px",
      left: mousePosition.x + "px",
    }
    cursor_hover_image = <img style={ch_style} src={image} alt="cursor hover" />
  }

  let homepage_copy = (
    <div className={"homepage-text" + (loading ? " loading" : "")}>
      Paris in July is a digital basement full of vintage{" "}
      <span
        className="poiret-one homepage-link"
        onMouseMove={_onMouseMove.bind(this)}
        onMouseEnter={() => dispatch(setCursorHoverImage("six-feet"))}
        onMouseLeave={() => dispatch(setCursorHoverImage(null))}
      >
        <Link to="/shop/six-feet">erotic paraphenalia</Link>
      </span>{" "}
      and booby traps. We’ve got a “CPR manequin” you can{" "}
      <span
        className="finger-paint homepage-link"
        onMouseMove={_onMouseMove.bind(this)}
        onMouseEnter={() => dispatch(setCursorHoverImage("shop"))}
        onMouseLeave={() => dispatch(setCursorHoverImage(null))}
      >
        <Link to="/shop">hang your coat</Link>
      </span>{" "}
      on and a radio that only plays{" "}
      <span
        className="faster-one homepage-link"
        onMouseMove={_onMouseMove.bind(this)}
        onMouseEnter={() => dispatch(setCursorHoverImage("radio-hour"))}
        onMouseLeave={() => dispatch(setCursorHoverImage(null))}
      >
        <Link to="/radio-hour">corrupt MP3’s.</Link>
      </span>{" "}
      Also, please no{" "}
      <span
        className="chewy homepage-link"
        onMouseMove={_onMouseMove.bind(this)}
        onMouseEnter={() => dispatch(setCursorHoverImage("instagram"))}
        onMouseLeave={() => dispatch(setCursorHoverImage(null))}
      >
        <a href="https://www.instagram.com/parisinjuly_">flash photography,</a>
      </span>{" "}
      You will be issued a{" "}
      <span
        className="pirata-one homepage-link"
        onMouseMove={_onMouseMove.bind(this)}
        onMouseEnter={() => dispatch(setCursorHoverImage("reviews"))}
        onMouseLeave={() => dispatch(setCursorHoverImage(false))}
      >
        <Link to="/reviews">written citation</Link>
      </span>{" "}
      and be kicked out.
    </div>
  )

  let desktop_view = (
    <Row className="body-row d-none d-md-flex">
      <Col md={{ size: 12, offset: 0 }} className="body-col-container">
        <img
          className={"wordmark " + (loading ? "loading" : "")}
          src={wordmark}
          alt="paris in july"
        />
        {cursor_hover_image}
        {homepage_copy}
      </Col>
    </Row>
  )

  let mobile_view = (
    <Row className="body-row-mobile d-flex flex-column d-md-none">
      <Col>
        <div className="mobile-body-container">
          <img
            className={"wordmark-mobile " + (loading ? "loading" : "")}
            src={wordmark}
            alt="paris in july"
          />
          {homepage_copy}
        </div>
      </Col>
    </Row>
  )

  return (
    <Layout>
      <Container fluid>
        <SEO title="👋" />
        {desktop_view}
        {mobile_view}
      </Container>
    </Layout>
  )
}

export default connect(
  state => ({
    loading: state.home.loading,
    hideBodyCopy: state.home.hideBodyCopy,
    backgroundHoverImage: state.home.backgroundHoverImage,
    cursorHoverImage: state.home.cursorHoverImage,
    hoverImageCount: state.home.hoverImageCount,
    mousePosition: state.home.mousePosition,
    orderDetails: state.cart.orderDetails,
  }),
  null
)(IndexPage)
